import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Lander from "./lander/Lander";
import Navbar from "./nav/Navbar";
import Impressum from "./Impressum/Impressum";
import Velux from "./Velux/Velux";

function App() {
  return (
    <div className={"dark w-full bg-gray-300 h-100"}>
      <div className={"w-full md:w-3/4 h-100 bg-gray-700 m-auto"}>
        <Navbar/>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Lander/>}/>
            <Route path="/impressum" element={<Impressum/>}/>
            <Route path="/velux" element={<Velux/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
