import "./lander.css"

export default function Lander() {
  return (
    <div >
      <div className={"h-0"}>
        <img src={"https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/LanderPic.jpeg"} alt={"nope"} className={"min-w-full object from-current"} id={"titlePic"}/>
      </div>
      <div className={"relative rounded-box bg-gray-600 bg-opacity-30 p-5 m-5 mt-20"}>
        <p className={"z-10 dark text-white"}>
          <p className={"text-xl dark text-white"}>Hallo!</p><br/>
          Ich bin Jochen Meurer,
          Seit nunmehr 28 Jahren bin ich auf den Dächern Rund um Erftstadt zu Hause.
          Weiterbildungen lassen meinen Erfahrungsschatz von Jahr zu Jahr größer werden und diesen, Stelle ich meinen Kunden zu Verfügung!<br/><br/>
          Besonderen Wert lege ich darauf, meine Kunden eingehend und ehrlich zu beraten, bevor er seine Entscheidung trifft! ... denn „ geht nicht, gibt‘s nicht “!!<br/><br/>

          Seit .... 2021 bin ich als Dachdecker im traditionellen Reisegewerbe nach Paragraph 55 GewO selbstständig und baue meinen Kundenkreis stetig aus.
          Überzeugen Sie sich selbst!<br/><br/>
          Ob... - Steildach, - Flachdach, - Dachflächenfenster, - Klempner/Spenglerarbeiten, - Balkon/Terassensanierung, - Zimmermann arbeiten, gemeinsam finden wir eine Perfekt Passende Lösung.<br/>
          Es grüßt freundlichst<br/><br/>
          Ihr Jochen Meurer</p>
      </div>
      <p className={"text-2xl pl-5 text-white"}>Bilderstrecke</p>
      <div className={"relative p-5 flex w-full md:flex-row w-full p-5 relative gap-4 overflow-x-auto md:overflow-x-hidden md:flex-wrap md:items-center md:justify-center"}>
        <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/Treppe.jpeg" alt={""} className={"w-80 rounded-box"}/>
        <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/Fenster1Links.jpeg" alt={""} className={"w-80 rounded-box"}/>
        <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/Treppe.jpeg" alt={""} className={"w-80 rounded-box"}/>
        <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/DachAttic.jpeg" alt={""} className={"w-80 rounded-box"}/>
        <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/DachMitFenster.jpeg" alt={""} className={"w-80 rounded-box"}/>
        <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/Daechlein.jpeg" alt={""} className={"w-80 rounded-box"}/>
        <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/BadFenster.jpeg" alt={""} className={"w-80 rounded-box h-auto w-auto"}/>
        <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/SchornsteinDachSteil.jpeg" alt={""} className={"w-80 rounded-box"}/>
      </div>
    </div>
  )
}
