export default function Velux() {
  return (
    <div className={"h-screen"}>
      <div className={"relative pt-20"}>
        <p className={"text-2xl pl-5"}>Bilderstrecke VELUX</p>
        <div className={"relative p-5 flex w-full md:flex-row w-full p-5 relative gap-4 overflow-x-auto md:overflow-x-hidden md:flex-wrap md:items-center md:justify-center"}>
          <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/1.jpeg" alt={""} className={"w-80 rounded-box"}/>
          <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/2.jpeg" alt={""} className={"w-80 rounded-box"}/>
          <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/3.jpeg" alt={""} className={"w-80 rounded-box"}/>
          <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/4.jpeg" alt={""} className={"w-80 rounded-box"}/>
          <img src="https://jochen-web-bilder.s3.eu-central-1.amazonaws.com/5.jpeg" alt={""} className={"w-80 rounded-box"}/>
        </div>
      </div>
    </div>
  )
}
